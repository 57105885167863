.reports-rich-text-container-div {
    position: relative;
    user-select: text;
}

/* Wrap text (fixes https://github.com/ProseMirror/prosemirror/issues/542) */
.reports-rich-text-container.ProseMirror {
    overflow-wrap: break-word;
}

.reports-rich-text-container p {
    padding: 0px;
    margin: 6px 4px;
}

.reports-rich-text-container p.align-left {
    text-align: left;
}

.reports-rich-text-container p.align-right {
    text-align: right;
}

.reports-rich-text-container p.align-center {
    text-align: center;
}

.reports-rich-text-container p.align-justify {
    text-align: justify;
}
