// gives access to the colors documented here: http://blueprintjs.com/docs/v2/#core/variables

@import '~@blueprintjs/core/lib/scss/variables';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2';

$header-height: 56px;
$background-color: $white;
$border-color: $light-gray1;

body {
    // Borealis will add this to body if DS.StyleProvider is used anywhere.
    // It needs to be unset until we have a HS-specific theme available.
    -webkit-font-smoothing: unset !important;
}

#root {
    height: 100%;
    position: relative;
}

input,
textarea {
    &[disabled] {
        cursor: default;
    }
}

.disabled {
    pointer-events: none;
}

// Override report document color picker styles
.chrome-picker {
    padding: 6px;
    box-shadow: none !important;
}

.activity-popover {
    padding: 8px;
    min-width: 200px;
    max-height: 240px;
    overflow: auto;
}

.select-popover {
    .bp3-popover-content {
        padding: 5px;

        .bp3-menu {
            padding: 0;

            // Add extra padding if menu is not first child (has filter input)
            &:not(:first-child) {
                padding-top: 5px;
            }
        }
    }
}

.reports {
    // font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-family: Helvetica, Arial, sans-serif;

    .sidebar {
        flex: 0 0 auto;
        background-color: $background-color;
    }

    .branding {
        height: $header-height;
        background: url(../static/helioscope-black.png) no-repeat 0 0;
        background-size: 180px auto;
        background-position: center center;
        margin-left: -24px;
        border-bottom: 1px solid $light-gray2;
    }

    .navigation {
    }

    .avatar {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        color: #ffffff;
        background-color: #60c4f4;
        cursor: pointer;
        overflow: hidden;
        font-size: 14px;
    }

    .avatar .#{$ns}-icon {
        margin-right: 6px;
    }

    .content-header {
        .context-container {
            position: absolute;
            right: 50px;
            margin-right: 20px;
        }
    }

    .activity-container {
        display: flex;
        margin-right: 0px;
        height: 100%;
        width: 50px;

        .activity-target-inner {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .content-pane {
        position: relative;
    }

    .content {
        background-color: $light-gray3;
        // border-left: 1px solid $gray5;
        // border-top: 1px solid $gray5;
        padding: 0;

        .sub-container {
            background-color: #f4f4f4;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;

            .sub-sidebar {
                flex-basis: auto;
                flex-grow: 0;
                flex-shrink: 0;
                width: 280px;
                background-color: #ececec;
                overflow-x: hidden;
                overflow-y: auto;
            }

            .sub-sidebar.split {
                border-right: 1px solid #d4d4d4;
            }

            .sub-content-container {
                flex-basis: 0%;
                flex-grow: 1;
                flex-shrink: 1;
                overflow: auto;
                padding: 0px;
                min-height: 100%;

                display: flex;
                flex-flow: column;

                .sub-content-inner {
                    flex: 1 1 100%;
                }

                .sub-content-footer {
                    background-color: #e0e0e0;
                    margin-top: 16px;
                    width: 100%;
                    min-height: 18px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.42);
                }
            }
        }

        // TODO: Replace with `reports/components/core/containers/Card`
        .sub-content-box-1 {
            background-color: #ffffff;
            margin: 8px 4px;
            padding: 12px;
            border: 1px solid $border-color;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.125);
            font-size: 14px;
            font-weight: 400;
            color: $dark-gray1;
        }

        .financial-step-box {
            margin: 8px 4px;
            padding: 16px;
            border: 1px solid $border-color;
            border-radius: 8px;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);

            .param-list {
                margin-top: 12px;
                margin-bottom: 4px;
            }

            .step-header {
                font-weight: 700;
                display: flex;
                height: 36px;
                align-items: center;
            }
        }

        .finance-parameter-entry {
            margin: 4px 2px;

            .finance-parameter-line {
                display: flex;
                align-items: center;

                .line-text {
                    width: 216px;
                    padding: 0px 2px;
                    vertical-align: top;
                }

                .line-edit {
                    flex: 0 1 33%;
                    padding: 0px 8px;
                    vertical-align: top;
                }
            }

            .expand-edit {
                margin: 4px;
                padding: 4px 8px;
                background-color: #f4f4f4;
                border-radius: 4px;
            }
        }

        .content-header {
            .content-header-bottom {
                background-color: #ffffff;
                border-bottom: 1px solid $border-color;

                .inner-default {
                    padding: 12px 14px 12px 14px;
                }
            }

            width: 100%;
            margin: 0px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.42);
        }

        .editable-title {
            font-size: 18px;
            font-weight: 500;
            max-width: 100%;
            min-width: 100px;
        }
    }

    .tabs-wider .#{$ns}-tab-list {
        width: 100%;
    }

}

.sortable-container-vertical {
    background-color: white;
}

// Generic hover state class
.hover-select-state:not(.disabled) {
    &:hover {
        cursor: pointer;
        border: 1px solid $blue3;
        box-shadow: 0 0 1px $blue5;
    }
}

.number-table {
    th {
        text-align: center;
    }
    td,
    th.data-cell {
        text-align: right;
    }

    td.text-cell,
    th.text-cell {
        text-align: left;
    }
}

// Admin persistent toast.
// Hide the close button via styles because there is no API option in current blueprint version (3.x)
.admin-user-toast {
    button {
        display: none;
    }
}

// Increase Blueprint toast z-index to prevent overlap with DS overlays.
.bp3-toast-container {
    z-index: 650;
}
