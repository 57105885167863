/* simple flexbox based grid, cripped from http://foundation.zurb.com/apps/page-templates.html */

html, body {
    height: 100%
}

body {
    position: relative;
}

.grid-frame {
    display: flex;
    height: 100%;
    position: relative;
    overflow: hidden;
    flex: 1 1;
    flex-flow: row wrap;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    order: 0;
}

.grid-block {
    display: flex;
    height: 100%;
    /* position: relative; */
    overflow: hidden;
    flex: 1 1;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;
    order: 0;
    overflow-y: auto;
}

.vertical.grid-block {
    flex-flow: column nowrap;
    align-items: stretch;
}

.grid-content {
    display: block;
    overflow-y: auto;
    padding: 0 1rem;
    position: relative;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    flex: 1 1;
}

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    display: inline-block;
    vertical-align: middle;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.shrink.grid-content {
    flex: 0 0 auto;
}

.collapse {
    padding: 0;
}
