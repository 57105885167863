@import "~@blueprintjs/core/lib/scss/variables";


table.clickable td {
    cursor: pointer;
}

.table tr.active td {
    background-color: $pt-text-selection-color!important;
}


.underlined-header {
    padding-bottom: 4px;
    border-bottom: 1px solid #DCDCDC;
}
