
.financials-grid .row-header {
    border-right: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    background: #e8e8e8;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 2px;
}

.financials-grid .grid-row .column-header {
    display: inline-block;
    border-left: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    background: #e8e8e8;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 2px;
}

.financials-grid .grid-row .row-cell {
    display: inline-block;
    border-left: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    background: #f4f4f4;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 2px;
    cursor: cell;
    user-select: none;
}

.financials-grid .grid-row .row-cell .text,
.financials-grid .grid-row .column-header .text,
.financials-grid .row-header .text {
    vertical-align: middle;
    display: inline-block;
}

.grid-overlay .edit-cell {
    border: none;
    padding: 0px 2px;
}
